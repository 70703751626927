<template>
	<div
		class="justify-space-between-pc gap-20 mt-50-pc mt-20-m"
	>
		<div class="flex-1">
			<div>
				<div><h3>결제 수단</h3></div>
				<div class="justify-space-between gap-20 mt-20">
					<div
						v-for="(type, index) in list_items"
						:key="'item_' + index"
						class="box radius-20 pa-40-pc pa-10-15-m text-center flex-1 item-point cursor-pointer"
						:class="{ on: type.pg_uid == item.pg_uid}"
						@click="setItem(type)"
					>
						<div><img :src="require(`@/assets/images${$skin.img}sub/point-${type.type}.svg`)" style="width: 60px" class="width-30-m" /></div>
						<div class="text-18 size-px-14-m font-weight-bold mt-10">{{ type.name }}</div>
						<div class="text-14 size-px-9-m">충전 수수료 {{ type.pg_fee }}% <span class="hide-m">발생됩니다.</span></div>
					</div>

				</div>
			</div>
			<div class="mt-30">
				<div><h3>충전 금액</h3></div>
				<ul class="justify-start flex-row-wrap gap-20-pc gap-10-m mt-20">
					<li
						v-for="(charge, c_index) in items_charge"
						:key="'charge_' + c_index"
						style=""
						class="text-center pa-10 box radius-10 cursor-pointer item-charge width-point"
						:class="{ on: charge == item_charge}"
						@click="setCharge(charge)"
					>{{ charge * Math.pow(10, pow) | makeComma}}원</li>
				</ul>
			</div>
			<hr class="under-line mt-20"/>
			<div class="mt-30 justify-space-between-pc ">
				<div class="flex-1">충전금액 직접입력</div>
				<div class="flex-2 mt-10-m">
					<div class="input-box width-100 radius-10 justify-space-between gap-10">
						<input v-model="item_input.amount" @keyup="setAmount" class="flex-1 text-right" :rules="$rules.max(item_input, 'amount', 7)" type="number"/>
					</div>
					<div class="mt-10 color-error">최소 충전금액 1000원</div>
				</div>
			</div>
		</div>
		<div class="width-480-pc mt-30-m">
			<div class="box radius-20 pa-20 mt-20">
				<div class="justify-space-between h3 under-line pb-10">
					<span>결제 금액</span>
					<span>{{ amount | makeComma }}원</span>
				</div>
				<div class="mt-10 justify-space-between h3 under-line pb-10 color-red">
					<span>충전 POINT</span>
					<span>{{ point | makeComma }}P</span>
				</div>
				<div class="mt-20 text-14 size-px-12-m">
					<label class=" justify-space-between gap-10">
						<v-icon
							v-if="is_agree"
							@click="is_agree = false"
							class="color-blue size-px-16-m"
						>mdi mdi-checkbox-marked</v-icon>
						<v-icon
							v-else
							@click="is_agree = true"
							class="size-px-16-m"
						>mdi mdi-checkbox-blank-outline</v-icon>
						<div>
							<span class="color-red font-weight-600 letter">(필수)</span> 충전할 금액을 확인 하였으며,결제에 필요한 결제정보 제공에 동의 합니다.
						</div>
					</label>
				</div>
				<div class="mt-20 text-right">
					<button
						v-if="is_confirm"
						class="btn-round  btn-fill"
						@click="onCharge"

						:disabled="is_disabled"
					>충전하기</button>
					<button
						v-else
						class="btn-round  btn-fill"
						@click="onConfirm('confirm')"

					>본인인증</button>
				</div>
			</div>
		</div>

		<PopupConfirm
			v-if="is_on_charge"

			@cancel="is_on_charge = false"
			@click="postCharge"
		>
			<template
				v-slot:title
			>포인트 충전</template>
			<template
				v-slot:main-txt
			>{{ item.name }} {{ amount | makeComma}}원 </template>
			<template
				v-slot:sub-txt
			>{{ item.name }}로 포인트를 충전합니다</template>
			<template
				v-slot:name-confirm
			>충전하기</template>
		</PopupConfirm>

		<popup-layer
			v-if="item.ui_type == 'popup' && is_on_payment"
		>
			<template
				slot="body"
			>
				<iframe
					:src="danal_url"
					class="full-height"
					style="max-width: 480px"
				></iframe>
			</template>
		</popup-layer>
		<iframe
			v-else-if="(item.ui_type == 'frame') && is_on_payment"
			:src="danal_url"
			class="full-height full-width"
			style="display: none"
		></iframe>

	</div>
</template>

<script>
	import PopupConfirm from "../Layout/PopupConfirm";
	import PopupLayer from "@/view/Layout/PopupLayer";
	export default {
		name: 'PointItems'
		, components: {PopupLayer, PopupConfirm}
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: '포인트 충전'
					,title: false
					,search: true
					,bottom: true
				}
				, items: [

				]
				, item: {
				}
				, amount: 0
				, items_charge: [
					1, 2, 3, 5, 10, 20, 30, 50
				]
				, item_charge: this.$route.query.point ? this.$route.query.point : 1
				, item_input: {
					amount: ''
				}
				, is_on_charge: false
				, is_agree: false
				, is_confirm: true
				, nice_url: ''
				, is_on_payment: false
				, danal_url: ''
				, baseUrl: ''
				, pow: 4
				, set_pow: 2
			}
		}
		, computed: {
			is_disabled: function(){
				let t = true
				if(this.is_agree && Number(this.amount) >= 1000){
					t = false
				}
				return t
			}
			, point: function(){
				let t = 0
				this.items.filter( (item) => {

					if(this.item.pg_uid == item.pg_uid){
						t = Math.floor(this.amount * (100 - item.pg_fee) / 100)
					}
				})
				return t
			}
			, list_items: function(){
				return this.items.filter((item) => {
					if(item.pg_status){
						return item
					}
				})
			}
		}
		, methods: {
			getData: async function(){
				this.$bus.$emit('on', true)
				try {
					const result = await this.$request.init({
						method: 'get'
						, url: 'main/getPointItems'
					})

					if (result.success) {
						this.items = result.data
						this.item = this.items[0]
					} else {
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', {type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, setItem: function(item){
				// console.log(item)
				this.is_on_payment = false
				this.item = item
			}
			, setCharge: function(item){
				this.item_charge = item
				this.amount = Number(item) * Math.pow(10, this.pow)
				this.item_input.amount = ''
			}
			, setAmount: function(){
				// this.amount = Number((this.item_input.amount).slice(-5)) * Math.pow(10, this.pow)
				this.amount = this.item_input.amount
				this.item_charge = ''
			}
			, onCharge: function(){
				this.is_on_charge = true
			}
			, postCharge: async function(){
				this.$bus.$emit('on', true)
				this.is_on_payment = false
				try {
					const result = await this.$request.init({
						method: 'post'
						, url: 'main/postPoint'
						, data: {
							type: this.item.type
							, amount: this.amount
							, point: this.point
							, pg_uid: this.item.pg_uid
						}
					})

					if (result.success) {

						this.danal_url = this.baseUrl + result.data

						this.is_on_payment = true
					} else {
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', {type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, onConfirm: function(type){

				this.nice_url = this.baseUrl + '/nice/request?type=' + type + '&P=' + sessionStorage.getItem(process.env.VUE_APP_NAME + 'AT')

				window.open(this.nice_url, 'nice', 'width=320px')
			}
			, niceResult: function(e){
				// console.log('niceResult', e)
				try {
					let domains = process.env.VUE_APP_DOMAIN.split('|')
					let t = false
					for(let i = 0; i < domains.length; i++){
						if(e.origin.indexOf(domains[i]) > -1){
							t = true
						}
					}
					if(t){
						// console.log('dailycon', e)
						let data = e?.data
						if(data && typeof data == 'string'){
							data = JSON.parse(data)
							if(data.type == 'nice_result'){
								// console.log('nice_result', data)
								if(data.result){
									this.is_confirm = true
									this.$bus.$emit('notify', { type: 'success', message: '본인인증이 완료되었습니다'})
								}else{
									throw data.message
								}
							}
						}
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
					this.is_on_charge = false
					this.is_on_payment = false
				}finally {
					this.is_on_confirm = false
				}
			}
			, chargeResult: function(e){
				// console.log('chargeResult', e)
				try {
					let domains = process.env.VUE_APP_DOMAIN.split('|')
					let t = false
					for(let i = 0; i < domains.length; i++){
						if(e.origin.indexOf(domains[i]) > -1){
							t = true
						}
					}
					if(t){// console.log('dailycon', e)
						let data = e?.data
						if(data && typeof data == 'string') {
							data = JSON.parse(data)
							if(data.type == 'danal_tel') {
								// console.log('danal_tel', data)
								if (data.result) {
									this.$bus.$emit('to', {name: 'PointList'})
								} else {
									throw data.message
								}
							}
						}
					}
				}catch (e){
					this.$bus.$emit('notify', { type: 'error', message: e})
					this.is_on_charge = false
					this.is_on_payment = false
				}finally {
					this.is_on_confirm = false
				}
			}
		}
		, destroyed() {
			window.removeEventListener('message', this.niceResult)
		}
		, created() {
			if(this.user && !this.user.member_id){
				alert('회원가입 후 이용가능합니다.')
				this.$bus.$emit('goBack')
			}
			if(this.user.member_level < 1){
				alert('인증회원만 이용가능합니다')
				this.$bus.$emit('goBack')
			}
			this.$bus.$emit('onLoad', this.program)

			this.amount = this.item_charge * Math.pow(10, this.pow)

			this.baseUrl = this.$danal_url

			this.getData()

			window.removeEventListener('message', this.niceResult)
			window.removeEventListener('message', this.chargeResult)
			setTimeout( () => {
				window.addEventListener('message', this.niceResult)
				window.addEventListener('message', this.chargeResult)
			}, 100)
		}
	}
</script>

<style>


	@media (max-width: 720px) {
		.width-point {
			width: calc(33.33% - (20px / 3))
		}

		.letter {
			letter-spacing: -0.204px;
		}
	}

	@media (min-width: 720px) {
		.width-point {
			width: calc(33.33% - (40px / 3))
		}
	}
</style>